.CreateProfile-background {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/csi-app-farm.appspot.com/o/Rainbow_Over_the_Farm_Photo.jpg?alt=media&token=22942b3f-76f8-4933-b309-e8f001e6b81a");
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-position: 56% 50%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(0px);
    transform: scale(1.2); /* gets rid of blurry edges of image */
    z-index: -1;
    filter: blur(10px);
    opacity: 1;
}

.CreateProfile {
    max-width: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 35px;

}

.CreateProfile-Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateProfile-alert-hidden {
    margin-bottom: 20px;
    color: red;
    transform: scale(.5);
}

.CreateProfile-alert-visible {
    margin-bottom: 20px;
    text-align: center;
    color: red;
    transform: scale(1.1);
    translate: all 1000ms;
}