

.Home-Enter {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  /*transform: translate(-100vw, 0);*/
  background-color: #363636;
}

.Home-Background {
  background-image: url("./images/home-background.jpg");
 /*background-image: url("https://firebasestorage.googleapis.com/v0/b/csi-app-farm.appspot.com/o/Rainbow_Over_the_Farm_Photo.jpg?alt=media&token=22942b3f-76f8-4933-b309-e8f001e6b81a");
background-image: url("https://res.cloudinary.com/campbellsci/image/upload/f_auto,w_749,h_317,c_limit/11267.jpg");
 background-image: url("https://i.pinimg.com/736x/a6/c8/de/a6c8def5c8b820d67d1340005a3bee13--love-painting-art-nature.jpg");
  background-image: url("https://media.pixcove.com/K/9/5/Grain-Ounce-Rye-Wheat-Free-Image-Rye-Field-Agricul-4894.jpg");
  /*background-image: url("https://www.artrenewal.org/secureimages/artwork/785/2785/15941/the_sun-lit_pines-large.jpg?&format=jpg&mode=max&w=577");*/
  position: fixed;
  top: 0;
  bottom: 0;
  background-position: 56% 50%;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(0px);
  transform: scale(1.2); /* gets rid of blurry edges of image */
  z-index: -1000;
  opacity: .90;
}


.Home-Icon-Wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  width: 50px;
  cursor: pointer;
  /*border: 1px solid red;*/
}

.Home-Icons-Wrapper {
  margin: auto;
  margin-top: 40px;
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*border: 1px solid white;*/
}

.Home-Icons-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 370px;
  margin: 10px 35px;
  /*border: 1px solid yellow;*/
}

.Home-Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  height:55px;
  width: 55px;
  border-radius: 35%;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
  /*box-shadow: -1px 1px 3px 2px rgba(0,0,0,0.1)*/
}

.Home-Icon-Title {
  font-size: 85%;
  font-weight: normal;
   /*letter-spacing: 0.5px;*/
  width: 70px;
  text-align: center;
  line-height: 1.3em;
  color: rgba(255,255,255, 1);
	text-shadow: -1px 1px 1px #000000;
	-webkit-text-shadow: -1px 1px 1px #000000;
  -moz-text-shadow: -1px 1px 1px #000000; 
  /*border: 1px solid yellow;*/
}

.Home-Icon-hidden {
  opacity: 0;
}

.Home-Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  margin: 0;
  height: 55px;
  /*background-color: rgba(57,64,73,0.8);*/
  background-color: rgb(250, 250, 250);
}

.Home-Navbar-Wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 370px;
  margin: 0 35px;
}

/*.Home-Navbar-logo {
  margin: 3px 15px 3px 0;
  opacity: .8;
}*/

.Home-Navbar-Title {
  font-size: 130%;
  font-weight: 600;
  margin-top: 5px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);
}