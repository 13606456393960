.Signin-background {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/csi-app-farm.appspot.com/o/Rainbow_Over_the_Farm_Photo.jpg?alt=media&token=22942b3f-76f8-4933-b309-e8f001e6b81a");
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-position: 56% 50%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(0px);
    transform: scale(1.2); /* gets rid of blurry edges of image */
    z-index: -1000;
    opacity: .90;
}

.Signin {
    max-width: 350px;
    padding: 60px 20px 0 20px;
}

.Signin-form {
    width: 100%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-link{
    color: rgba(255,255,255,0.8);
}
.btn-link:hover {
    color: rgba(255,255,255,1);
}

/*.btn-outline-secondary:not(:disabled):not(.disabled):link {
    border-color: white;
    color: white;
    border-radius: 10px;
    padding: 7px 40px;
    background-color: transparent;
} */

.btn-outline-secondary:not(:disabled):not(.disabled) {
    background-color: transparent;
    color: white;
    border-color: white;
    border-radius: 10px;
    box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    border-color: transparent;
    background-color: rgba(255,255,255,0.5);
    color: black;
    box-shadow: none;
}


.btn-outline-secondary:not(:disabled):not(.disabled):active
 {
    border: none;
    background-color: rgba(255,255,255,1);
    box-shadow: none;
    color: black;
}

.modal-content{
    border-radius: 10px;
    font-weight: 500;
}

.modal-title{
    font-weight: 600;
    font-size: 120%;
    opacity: 0.4
}

.modal-body {
    padding: 15px 25px;
}

.form-group label {
    font-weight: 500;
}

.form-control{
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}


.Submit-button {
    border-radius: 10px;
    margin-top: 15px;
}