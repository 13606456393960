

.Assess-wrapper {
  /*background-image: url("./images/leadership-background-optimized.jpg");
  background-image: url("https://media.pixcove.com/K/9/5/Grain-Ounce-Rye-Wheat-Free-Image-Rye-Field-Agricul-4894.jpg");
  background-image: url("https://www.artrenewal.org/secureimages/artwork/785/2785/15941/the_sun-lit_pines-large.jpg?&format=jpg&mode=max&w=577");*/

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);  /* gets rid of blurry edges of image */
  z-index: -2;
  display: flex;
  flex-direction: column;
  align-items: center;


  opacity: 1;
  background-color: #e6e6e6;
  margin: 0 auto;
  max-width: 600px;
  z-index: 1001;
  /*box-shadow: 0px -6px 45px rgba(0, 0, 0, 0.05)*/
  box-shadow: 0px -6px 35px rgba(255, 255, 255, 0.7)
}

.Assess-description-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  font-weight: 500;
}
.Assess-heading {
  display: flex;
  flex-direction: row;
  font-size: 130%;
  font-weight: 600;
}
.Assess-heading-icon {
  margin-top: -2px;
  margin-right: 5px;
  margin-left: -5px;
  height: 30px;
}
.Assess-button-wrapper {
  margin: 20px 30px;
}

/*
.LC-Button-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}


.LC-Button-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height:50vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid lightblue;
}



.CCButton {
  border: none;
  color: #777777;
  font-size: 115%;
  letter-spacing: 0.075rem;
  box-shadow: 4px 3px 6px rgba(0,0,0,0.4), -4px -5px 5px rgba(255,255,255,0.8), 0 0 6px #f1f1f1 ;
  background-image: linear-gradient(to bottom right, #d9d9d9, #f1f1f1);
}

.CCButton:a {
  background-color: #e6e6e6;
  border: none;
  outline: none;
}

.CCButton:hover, 
.CCButton:active,
.CCButton:visited {
  color: #000000;
  outline: none;
}

.CCButton:focus {
  outline: none;
}
*/