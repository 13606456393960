.Introduction {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: none;
  transform: translateY(100vh);
  color: rgba(0,0,0,0.90);
  background-color: rgba(255,255,255,1);
}

/*.Introduction-content {

}*/

.Introduction-content h3 {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0;
}

.overflow-wrapper {
  padding: 8vw;
  background-color: rgba(255,255,255, 0);
  width: 100%;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.Introduction-page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Introduction-icon {
  height: 65px;
  width: 65px;
  margin: 40px;
  margin-top: 0;
  border-radius: 50%;
  background-color: lightgray;
}

.Introduction-illustration {
  width: 200px;
  height: 200px;
  background-color: rgb(182, 182, 182);
  margin: 15px auto 25px auto;
}